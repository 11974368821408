<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  <v-layout wrap row xs12>
    <v-menu ref="dateFrom"
            lazy
            :close-on-content-click="false"
            v-model="dateFrom"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="from">
        <v-text-field slot="activator"
                      label="С: "
                      v-model="from"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="from" @input="$refs.dateFrom.save(from)"></v-date-picker>
    </v-menu>
    <v-menu ref="dateTo"
            lazy
            :close-on-content-click="false"
            v-model="dateTo"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="to">
        <v-text-field slot="activator"
                      label="По: "
                      v-model="to"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="to" @input="$refs.dateTo.save(to)"></v-date-picker>
    </v-menu>
    <v-select label="Выберите точку продажи"
      :items="places"
      item-text="name"
      item-value="id"
      v-model="selectedPlaceId"></v-select>
    <v-select label="Выберите статус заказа"
      :items="orderStates"
      item-text="text"
      item-value="value"
      v-model="selectedState"
      >
    </v-select>
    <v-btn color="info" @click="updateStats">Показать</v-btn>
    <v-btn color="info" @click="onReset">Очистить</v-btn>
  </v-layout>
  </v-container>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  
    <v-layout wrap>
      
      
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="green"
          icon="mdi-cash"
          title="Оборот"
          :value="orderStats.totalSum"
          small-value="₽"
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="orange"
          icon="mdi-content-copy"
          title="Число товаров"
          :value="orderStats.totalCount"
          small-value=""
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="red"
          icon="mdi-account"
          title="Уникальных покупателей"
          :value="orderStats.totalUsers"
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>

      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Товары"
          text="Статистика по товарам"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Товары в заказах"
          text="Статистика по товарам в заказах"
        >
          <v-data-table
            :headers="orderHeaders"
            :items="allShopItems"
            v-if="places"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.date | date }} {{ item.date | time }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.price * item.amount }}</td>
              <td>{{ getPlaceName(item.placeId) }}</td>
              <td>{{ item.paymentOrderId }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  data () {
    return {
      dateFrom: null,
      from: null,
      dateTo: null,
      to: null,
      places: [],
      selectedPlaceId: null,
      selectedState: 0,
      orderStats: null,
      orderStates: [
        {text: 'Все', value: 0},
        {text: 'Не оплаченные', value: 1},
        {text: 'Оплаченные', value: 2},
        {text: 'Выданы', value: 3},
      ],
      dailySalesChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            

          ]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      
    headers: [
      {
        sortable: true,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'amount'
      },
      {
        sortable: true,
        text: 'Сумма, ₽',
        value: 'price',
        
      }
    ],
    items: [

    ],
    orderHeaders: [
      {
        sortable: true,
        text: 'Дата',
        value: 'date'
      },
      {
        sortable: true,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'amount'
      },
      {
        sortable: true,
        text: 'Цена, ₽',
        value: 'price',
        
      },
      {
        sortable: true,
        text: 'Сумма, ₽',
        value: 'price*amount',
        
      },
      {
        text: 'Точка продаж',
        value: 'placeId'
      },
      // {
      //   sortable: true,
      //   text: 'Клиент',
      //   value: 'username',
        
      // },
      // {
      //   sortable: true,
      //   text: 'Email',
      //   value: 'email',
        
      // },
      {
        sortable: true,
        text: 'Payment Id',
        value: 'paymentOrderId',
        
      }
      
    ],
    allShopItems: [],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false
      }
    }
  },
  mounted () {
    let today = new Date(Date.now());
    this.from = today.toISOString().substr(0, 10)
    today.setDate(today.getDate() + 1)
    this.to = today.toISOString().substr(0, 10)
    this.getStats()
    this.getShopItems()
    this.getAllShopItems()
    this.getPlaces()
  },
  // watch: {
  //   from: function (val) {
  //       this.updateStats();
  //   },
  //   to: function (val) {
  //       this.updateStats();
  //   },
  //   selectedPlaceId: function(val){
  //     this.updateStats();
  //   },
  //   selectedState: function(val){
  //     this.updateStats();
  //   }
  // },
  methods: {
    updateStats(){
      this.getStats()
      this.getShopItems()
      this.getAllShopItems()
    },
    onReset() {
        let today = new Date(Date.now());
        this.from = today.toISOString().substr(0, 10)
        today.setDate(today.getDate() + 1)
        this.to = today.toISOString().substr(0, 10)
        this.selectedPlaceId = null;
        this.selectedState = 0;
        this.updateStats();
    },
    getStats () {
      var options = {
          params: {
              from: this.from,
              to: this.to,
              placeId: this.selectedPlaceId,
              state: this.selectedState
          }
      };
      this.$http.get('/Statistics/orders/total', options)
        .then(
          response => {
            this.orderStats = response.data
            
            // let array = [];
            // let arrayOfSum = [];
            // let arrayOfUsers = [];
            // for (var i=1;i<7;i++){
            //   let day = this.orderStats.weekOrders.find(x=>x.dayOfNumber == i)
            //   if (day)
            //   {
            //     array.push(day.count)
            //     arrayOfSum.push(day.sum)
            //     arrayOfUsers.push(day.users)
            //   }
            //   else{
            //     array.push(0)
            //     arrayOfSum.push(0)
            //     arrayOfUsers.push(0)
            //   }
                
            // }

            // let day = this.orderStats.weekOrders.find(x=>x.dayOfNumber == 0)
            // if (day){
            //   array.push(day.count)
            //   arrayOfSum.push(day.sum)
            //   arrayOfUsers.push(day.users)
            // } 
            // else{
            //   array.push(0)
            //   arrayOfSum.push(0)
            //   arrayOfUsers.push(0)
            // }
                
            // console.log(array)
            // console.log(Math.max(...arrayOfSum))
            // this.dailySalesChart.data.series.push(array)
            // this.dailySalesChart.options.high = Math.max(...array) + 10

            // this.dataCompletedTasksChart.data.series.push(arrayOfSum)
            // this.dataCompletedTasksChart.options.high = Math.max(...arrayOfSum) + 50

            // this.emailsSubscriptionChart.data.series.push(arrayOfUsers)
            // this.emailsSubscriptionChart.options.high = Math.max(...arrayOfUsers) + 5
          }
        )
    },
    getShopItems(){
      var options = {
          params: {
              from: this.from,
              to: this.to,
              placeId: this.selectedPlaceId,
              state: this.selectedState
          }
      };
      this.$http.get('/Statistics/shopitems/list', options)
        .then(response => {
          this.items = response.data
        })
    },
    getPlaceName(placeId){
      if (placeId && this.places)
        return this.places.find(x=>x.id == placeId).name;
      return placeId;
    },
    getAllShopItems(){
      var options = {
          params: {
              from: this.from,
              to: this.to,
              placeId: this.selectedPlaceId,
              state: this.selectedState
          }
      };
      this.$http.get('/Statistics/shopitems/all', options)
        .then(response => {
          this.allShopItems = response.data
        })
    },
    complete (index) {
      this.list[index] = !this.list[index]
    },
    getPlaces (){
      this.$http.get('/places')
        .then(response => {
          this.places = response.data
          this.places.unshift({'id': null, name: 'Все'})
        })
    },
  }
}
</script>
